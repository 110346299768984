import { Component, OnInit } from '@angular/core';
import { SigninComponent } from '../signin/signin.component';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private matDialog: MatDialog, public authService:AuthService) {}
  ngOnInit(): void {
  }

  signIn(){
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(SigninComponent, dialogConfig);
  }

  get userLoggedIn(){
    return this.authService.hasSignedInUser();
  }

  switchTheme(){
    console.log('localStorage', localStorage.getItem('theme'));
    if(localStorage.getItem('theme') == 'light' || !localStorage.getItem('theme')){
      console.log('setTheme', 'dark');
      document.documentElement.setAttribute("data-theme", "dark");
      localStorage.setItem('theme', 'dark');
    }
    else{
      console.log('setTheme', 'light');
      document.documentElement.setAttribute("data-theme", "light");
      localStorage.setItem('theme', 'light');
    }

  }

}
