import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { NewsComponent } from './components/news/news.component';
import { RankingsComponent } from './components/rankings/rankings.component';
import { SigninComponent } from './components/signin/signin.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'ranking', component: RankingsComponent },
  { path: 'news', component: NewsComponent },
  { path: 'signin', component: SigninComponent },
  { path: '',   redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
