<div *ngFor="let person of people">
  <details>
    <summary class="personSummary">
      <div>Name: {{person.Name}}</div>
      <div>Score: 0</div>
    </summary>
    <div class="actionsFrame">
    <div *ngFor="let action of person.Actions">
      <details>
        <summary class="actionSummary">   
          <a *ngIf='userLoggedIn | async' class="fa-solid fa-minus"></a>
          <div>Name: {{action.Name}}</div>
          <div>Score: 0</div>
          <a *ngIf='userLoggedIn | async' class="fa-solid fa-plus" ></a>
        </summary>
        <div>Date of Action: {{action.Date}}</div>
        <p>{{action.Description}}</p>
      </details>
    </div>
  </div>
  </details>
</div>