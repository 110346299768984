import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { getAuth, signInWithPopup } from "firebase/auth";
import { AuthProviderDefinition } from 'src/app/models/auth.provider.definition';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
   public authService: AuthService;
   public authProviders: AuthProviderDefinition[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: {name: string}, authService: AuthService, public dialogRef: MatDialogRef<SigninComponent>) { 
    this.authService = authService;
    this.authProviders = authService.getRegisteredProviders();
  }

  ngOnInit(): void {

  }

  public async signIn(itemName: string){
    let tim = this.authService.getProvider(itemName);
    this.authService.createUser(tim);
    this.dialogRef.close();
  }
}