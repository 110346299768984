<div class="center">
    <h2>The what</h2>

<p>The basic premise of Ancestral Score is ranking people good or bad.  These rankings will be given by the users of Ancestral Score 
    on a public personagaes actions.  The actions will be defined by historians employeed here at Ancestral Score to ensure that
    actions are not a subjective quanitiy.  These rankings will be avalible in a number of scopes; personal, 
    natonal, and geneological. A personal score effects the larger national and family score.</p>

<h2>The beginning of history</h2>
<h3>The Why</h3>

<p>
    The simple why is that this sort of ranking and analzying of people is inevitable.  
    At some point in the future there will be more people attempting to gain a doctoral degree in history than currently exist.
    Combine that horror with the fact that we are currently live in era where all of our communication and activity are tracked 
    and stored by a mulitude of actors.  All of that information will at some point be made widely avalible and thus be used for 
    mining and analysis. All of those asperiing historians matched with the earliest example of history that exists as it was
    lived mean that our time period is destined to be invesigated throughly and for most of us we should expect to be reduced 
    down to a simple metric, we call it an Ancestral Score. 
</p>

<h3>Where are we now</h3>

<p>Currently we do not have access to the data that future historians will have, so we are limiting the scope
    of investigation to public figures; polications, industrialists, and mad people as they are the only people
    we have enough data about to create a rank.  Our team of historians will release new rankable actors on a regular 
    cadenece from people who are dead at least 40 years.  
</p>
</div>