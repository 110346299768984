import { Filter } from "../models/Filter";
import { Person } from "../models/Person";

export interface IApp {
    Filters: Filter[];
    People: Person[];
  }
  
  export interface IAppState {
    AppState: IApp;
  }
  
  export const initialAppState: IApp = {
    Filters: null,
    People: null,
  };