import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Person } from 'src/app/models/Person';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { initialize } from 'src/app/store/actions/app.actions';
import { IAppState } from 'src/app/store/app.interface';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {
  public people: Person[];
  constructor(private storageService: StorageService , public authService:AuthService, private store: Store<IAppState>) { 
    
  }

  get userLoggedIn(){
    return this.authService.hasSignedInUser();
  }

  ngOnInit(): void {
    this.store.dispatch(initialize());
    this.storageService.getPeople(null)
      .then((people) => {
        console.log('Promise Complete', people);
        this.people = people;
    });
  }

}
