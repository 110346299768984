<nav>
    <ul>
      <li><strong>Ancestral Score</strong></li>
    </ul>
    <ul>
        <li><a href="home">Home</a></li>
        <li><a href="news">News</a></li>
        <li><a href="ranking">Rankings</a></li>
        
    </ul>
    <ul>
        <li>
            <a class="fa-solid fa-brush" (click)="switchTheme()"></a>
        </li>
        <li *ngIf='!(userLoggedIn | async)'><a class="fa fa-user" (click)="signIn()"></a></li>
        <li *ngIf='userLoggedIn | async'><a class="fa fa-sign-out" (click)="authService.signOut()"></a></li>
    </ul>
  </nav>