import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { login } from 'src/app/store/actions/app.actions';
import { IAppState } from 'src/app/store/app.interface';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  constructor(private store: Store<IAppState>) { }

  ngOnInit(): void {

  }

  login(): void{

  }

}
