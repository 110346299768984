import { getAuth, signInWithPopup, GoogleAuthProvider as firebaseGoogleAuthProvider } from "firebase/auth";
import { AuthProviderDefinition } from "../../../models/auth.provider.definition";
import { LocalUser } from "../../../models/LocalUser";

export class GoogleAuthProvider implements AuthProviderDefinition  {
    public name: string = 'Google';
    public styleName: string = 'googleButton';
    public iconSrc: string = "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg";
    public async login(): Promise<LocalUser>{
        const provider = new firebaseGoogleAuthProvider();
        const auth = getAuth();
        const user = await signInWithPopup(auth, provider)
          .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = firebaseGoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            return result.user;
          }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = firebaseGoogleAuthProvider.credentialFromError(error);
            // ...
          });
        return {displayName: user ? user.displayName: "", identifier: user? user.email: "", tags: []};
    }
}