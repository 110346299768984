import { Injectable } from '@angular/core';
import { AppService } from '../../app/app.service';
import { addDoc, collection, CollectionReference, doc, Firestore, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { LocalUser } from '../../../models/LocalUser';
import { Filter } from 'src/app/models/Filter';
import { Person } from 'src/app/models/Person';
import { Organization } from 'src/app/models/Organization';

@Injectable({
  providedIn: 'root'
})
export class FirebaseDB
 {
    private fireStore: Firestore;
    private usersCollection: CollectionReference;
    private peopleCollection: CollectionReference;
    private organizationsCollection: CollectionReference;
    constructor(private appService: AppService) { 
        this.fireStore = getFirestore(appService.getApplication());
        this.usersCollection = collection(this.fireStore, "users");
        this.peopleCollection = collection(this.fireStore, "people");
        this.organizationsCollection  = collection(this.fireStore, "organizations");
    }

    public async addUser(localUser:LocalUser){
        await addDoc(collection(this.fireStore, 'users'), {
            displayName: localUser.displayName, 
            identifier: localUser.identifier
        })
    }

    public async getUser(localUser: LocalUser){
        const userQuery = query(this.usersCollection, where("identifier", "==", localUser.identifier));
        const docs = await getDocs(userQuery);
        return docs.docs.length > 0 ? docs.docs[0].data(): null;
    }

    public async getPeople(filter: Filter): Promise<Person[]>{
        const jim = (await getDocs(this.peopleCollection)).docs;
        return jim.map((doc) => { 
            const pData = doc.data();
            return { 
                Name: pData.name,
                Birth: pData.birth, 
                Death: pData.death, 
                Description: pData.description,
                Added: pData.added,
                Actions: [{Name: "Action One", Description: "I am a description of an action", Date: new Date()},
                {Name: "Action Two", Description: "I am a description of an action", Date: new Date()}],
                Affiliations: null,
                Score: 0
            };
        });
    }

    public async getOrganizations(): Promise<Organization[]>{
        const jim = (await getDocs(this.peopleCollection)).docs;
        return jim.map((doc) => { 
            const pData = doc.data();
            return { 
                Name: pData.name,
                Description: pData.description,
                Date: pData.date
            };
        });
    }
}
