import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FilterComponent } from '../filter/filter.component';

@Component({
  selector: 'app-rankings',
  templateUrl: './rankings.component.html',
  styleUrls: ['./rankings.component.scss']
})
export class RankingsComponent implements OnInit {

  constructor(private matDialog: MatDialog ) { }

  ngOnInit(): void {
  }
  
  public filter(){
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(FilterComponent, dialogConfig);
  }
}
