import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { FirebaseError } from 'firebase/app';
import { getAuth, User } from 'firebase/auth';
import { Observable, of } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { AuthProviderDefinition } from '../../models/auth.provider.definition';
import { FacebookAuthProvider } from './facebook/facebook.Auth.Provider';
import { GoogleAuthProvider } from './google/google.Auth.Provider';
import { TwitterAuthProvider } from './twitter/twitter.Auth.Provider';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user:User;
  private authProviders = [new FacebookAuthProvider(), new GoogleAuthProvider(), new TwitterAuthProvider()];
  constructor(private storage: StorageService) { }

  public getProvider(providerName: string){
    return this.authProviders.find(provider => provider.name == providerName);
  }

  public getRegisteredProviders(): AuthProviderDefinition[]{
    return this.authProviders;
  }

  public async signOut(){
    const auth = getAuth();
    await auth.signOut();
    this.user = null;
  }

  public getSignedInUser(){
    const auth = getAuth();
    return auth.currentUser;
  }

  public hasSignedInUser(): Observable<boolean>
  {
    if(!this.user){
      this.user = this.getSignedInUser();
    }
    return of(!(this.user == null || this.user == undefined));
  }

  public async createUser(provider: AuthProviderDefinition)
  {
    const user = await provider.login();
    this.storage.createUser(user);
  }
}
