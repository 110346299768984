import { Injectable } from '@angular/core';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private app: FirebaseApp;
  constructor() 
  {     }

  public initialize()
  {
    const firebaseConfig = {
      apiKey: "AIzaSyCcTNa2o7O_XIsNU3cUtF1OUKdzMetSmG8",
      authDomain: "ancestral-score.firebaseapp.com",
      projectId: "ancestral-score",
      storageBucket: "ancestral-score.appspot.com",
      messagingSenderId: "555570380719",
      appId: "1:555570380719:web:8ecd87edc4a94b521a395a"
    };

    this.app = initializeApp(firebaseConfig);

    const appCheck =  initializeAppCheck(this.app, {
      provider: new ReCaptchaV3Provider('6LfmIUohAAAAAI9kYgBhoKTpiaQW-sae02h1RQz0'),
      isTokenAutoRefreshEnabled: true
    });
  }

  public getApplication(): FirebaseApp
  {
    return this.app;
  }
}
