import { Injectable } from '@angular/core';
import { Organization } from 'src/app/models/Organization';
import { Filter} from 'src/app/models/Filter';
import { Person } from 'src/app/models/Person';
import { LocalUser } from '../../models/LocalUser';
import { FirebaseDB } from './FirebaseDB/FirebaseDB';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private storage: FirebaseDB) { }

  public getUser(name: string){

  }

  public async createUser(localUser: LocalUser){
    console.log("create local user", localUser);
    const user = await this.storage.getUser(localUser);
    console.log("retrieved user", user);
    if(!user)
    { 
      console.log("creating user");
      this.storage.addUser(localUser);
    }
  }

  public async getPeople(filter: Filter): Promise<Person[]>{
    return await this.storage.getPeople(filter);
  }

  public async getAffiliations(): Promise<Organization[]>{
    return await this.storage.getOrganizations();
  }
}
