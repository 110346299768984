import { Component, OnInit } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"
import { AppService } from './services/app/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit {
  title = 'ancestralscore';
    
    constructor(appService: AppService){
      appService.initialize();
  }

  
  ngOnInit(): void {
    if(localStorage.getItem('theme') == 'light' || localStorage.getItem('theme') == null){
      document.documentElement.setAttribute("data-theme", "light");
    }
    else{
      document.documentElement.setAttribute("data-theme", "dark");
    }
  }
}
