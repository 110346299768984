import { AuthProviderDefinition } from "../../../models/auth.provider.definition";
import { getAuth, signInWithPopup, TwitterAuthProvider as firebaseTwitterAuthProvider } from "firebase/auth";
import { LocalUser } from "../../../models/LocalUser";

export class TwitterAuthProvider  implements AuthProviderDefinition {
    public name: string = 'Twitter';
    public styleName: string = 'twitterButton';
    public iconSrc: string = "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/twitter.svg";
    public async login(): Promise<LocalUser>{
        const provider = new firebaseTwitterAuthProvider();
        const auth = getAuth();
        await signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
            // You can use these server side with your app's credentials to access the Twitter API.
            const credential = firebaseTwitterAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const secret = credential.secret;
            const user = result.user;
            console.log(user);
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = firebaseTwitterAuthProvider.credentialFromError(error);
            // ...
        });
        return null;
    }
}