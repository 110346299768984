
import { createAction, props } from '@ngrx/store';
import { Filter } from 'src/app/models/Filter';
import { Person } from 'src/app/models/Person';

export const login = createAction(
  '[loginModule] log user Action',
  props<{ usernamae: string; password: string }>()
);

export const loginSuccess = createAction(
  '[loginModule] log user Success Action'
);

export const loginFail = createAction(
  '[loginModule] log user Fail Action',
  props<{ message: string }>()
);

export const updateFilters = createAction(
  '[updateFilters] log Action',
  props<{ filter: Filter[]}>()
);

export const clearFilters = createAction(
  '[clearFilters] log Action'
);

export const initialize = createAction(
  '[initialize] log Action'
);

export const initializeSuccess = createAction('[initialize] log Action', props<{people: Person[]}>());